@use "styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, row);

    &:hover {
        cursor: pointer;

        p {
            color: $color-primary !important;
        }
    }
}

.icon {
    display: inline-flex;
    position: relative;
    width: 26px;
    height: 26px;
    padding: 4px;
    margin-right: 12px;

    .iconInner {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.text {
    @include flex(center, flex-start, row);

    position: relative;
    min-height: 26px;
    flex: 1;
    
    p {
        transition: color .2s ease;
        color: $color-gray-dark;
    }
}