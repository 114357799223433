@use "@styles/helpers" as *;

.root {
    @include flex(center);

    flex-wrap: wrap;
    margin: 16px -12px 8px;

    @include breakpoint(sm) {
        margin: 0 -12px;
    }

    @include breakpoint(md) {
        margin: 32px -12px 8px;
    }
}

.halfBannerWrapper {
    @include flex(center, center);

    width: calc(100% - 24px);
    margin: 8px 12px;

    @include breakpoint(sm) {
        margin: 24px 12px;
        width: calc(50% - 24px);
    }
}
