@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, row);

    position: relative;
    z-index: z('homepage-intro');

    &:after {
        content: "";
    }

    @include breakpoint(lg, max) {
        display: none;
    }
}

// Side

.side {
    flex: 0 0 $desktop-homepage-mega-menu-side;
    width: $desktop-homepage-mega-menu-side;
    align-self: stretch;
    background-color: $color-white;

    .menu {
        box-shadow: 0 4px 10px rgb(0 0 0 / 7%);
        border: 1px solid $color-gray-light;
        border-top: none;
        border-radius: 0 0 8px 8px;

        &.expanded {
            border-radius: 0 0 0 8px;
        }
    }

    .menuScrollbar {
        position: relative;
        z-index: 51;
    }
}

// Main

.main {
    position: relative;
    min-width: 0;
    height: 620px;
    padding: 24px 0 0 32px;
    flex: 1;
    align-self: stretch;

    .menuContent {
        position: absolute;
        top: 0;
        left: -1px;
        width: calc(100% + 1px);
    }
}

// Slots

.slots {
    position: relative;
    z-index: 0;
}