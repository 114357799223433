@use "@styles/helpers" as *;

// Slots

// --- Banners Carousel Slot

.bannersCarouselSlot {
    .slider {
        padding: 0 16px 8px;
    }
}

// --- Navigation Slot

.navigationSlot {
    margin-top: 16px;
}

// --- Promotions Carousel Slot

.promotionsCarouselSlot {
    margin-top: 16px;
    margin-bottom: 0;
}