@use "@styles/helpers" as *;

.root {
    position: relative;
    margin: 16px -16px 8px;

    .carouselNavBtn,
    .carouselPagination {
        opacity: 1;
        transition: opacity .2s ease;
    }

    &.loading {
        .carouselNavBtn,
        .carouselPagination {
            pointer-events: none;
            opacity: 0;
        }
    }
    
    @include breakpoint(lg) {
        margin: 0;
    }
}

// Carousel

.carousel {
    .carouselPrevBtn {
        @include breakpoint(md, max) {
            left: 10px;
        }
    }

    .carouselNextBtn {
        @include breakpoint(md, max) {
            right: 10px;
        }
    }
}

.carouselPagination {
    transform: translateY(5px);
}

// --- Slider

.slider {
    padding-bottom: 32px;
    overflow: hidden;

    @include breakpoint(lg) {
        margin-bottom: 0;
        padding-bottom: 0;
        border-radius: 16px;
    }
}

// --- Slide

.slide {
    @include flex(center, center);

    max-width: 80%;
    overflow: hidden;

    @include breakpoint(sm) {
        max-width: 100%;

        &:last-of-type {
            margin-right: 16px;
        }
    }

    @include breakpoint(xl) {
        max-width: 80%;
    }
    @include breakpoint(1550px, min) {
        max-width: 70%;
    }
}

// --- Banner

.banner {
    @include rounded-corners(16px);

    height: 100%;
    opacity: 0.30;
    transition: opacity 0.6s ease;
    
    &.active{
        transition: opacity 0.2s ease-in-out;
        opacity: 1;
    }

    &.isUpToLg {
        opacity: 1;
    }
}

// Parallax

.parallaxContainer {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    @include breakpoint(lg) {
        border-radius: 16px;
    }
}

.parallaxLayer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}