@use "@styles/helpers" as *;

// Search Field

.searchFieldWrapper {
    .searchField {
        padding: 8px 0 0;
        min-height: 56px;
    }

    .searchFieldBtn {
        @include typography(body);

        width: 100%;
        height: 48px;
        color: $color-gray-semi;
        background-color: $color-gray-light;

        &:hover:not([disabled]) {
            background-color: $color-gray-light;
            color: $color-gray-semi;
        }

        &:focus:not([disabled]) {
            background-color: $color-gray-light;
            color: $color-gray-semi;
        }

        &:active:not([disabled]) {
            background-color: $color-gray-light;
            color: $color-gray-semi;
        }
    }

    .searchFieldBtnContent {
        @include flex(center, space-between, row);

        width: 100%;
    }

    @include breakpoint(lg) {
        display: none;
    }
}
