@use "styles/helpers" as *;

.root {
    @include flex(center, flex-start, row);
    @include rounded-corners(16px);

    width: 100%;
    margin: 16px 0;
    padding: 16px;
    background: $color-gray-light;
    flex: 1;

    .text {
        flex: 1;
    }

    @include breakpoint(lg) {
        width: calc(100% + 48px);
        margin: 0 -24px 24px;
        padding: 0 24px;
    }
}