@use "@styles/helpers" as *;

.layout {
    .layoutContainer {
        padding-top: 0;
    
        @include breakpoint(md) {
            padding: 0 16px 32px;
        }
    
        @include breakpoint(lg) {
            padding: 0 24px 32px;
        }
    }
}
