@use "styles/helpers" as *;

.modalContent {
    max-height: 85vh !important;
}

.slider {
    .navButton {
        background-color: transparent;
        box-shadow: none;
        border: none;
        padding: 8px;
    
        &:hover {
            background-color: transparent;
            color: $color-primary;
        }
    
        &.prev {
            left: 8px;
        }
    
        &.next {
            right: 8px;
        }
    }
    
    .pagination {
        margin-top: 12px;
    }

    .image {

    }
    
    .content {
        @include flex(flex-start, space-between, column);
    
        padding: 24px 16px 16px;
    
        .main {
            .title {
                @include typography(18px, 20px, 500, 500);
            }
        
            .separator {
                width: calc(100% + 32px);
                height: 1px;
                margin: 16px -16px 0;
                background-color: $color-gray-light;
            }
        
            .heading {
                @include typography(18px, 26px, 500, 500);
        
                margin-top: 16px;
        
                @include breakpoint(sm) {
                    margin-top: 30px;
                }
            }
        
            .description {
                margin-top: 16px;
        
                * {
                    @include typography(12px, 16px, 400, 400);
                }
            }
        }
    
        .actions {
            width: 100%;
            margin-top: 16px;
        }
    
        @include breakpoint(sm) {
            padding: 24px;
    
            .main {
                .separator {
                    width: calc(100% + 48px);
                    margin-left: -24px;
                    margin-right: 24px;
                }
            }
        }
    }

    @include breakpoint(sm) {
        .navButton {
            &.prev {
                left: 16px;
            }
        
            &.next {
                right: 16px;
            }
        }
    }
}

.slider.loading {
    .image {
        position: relative;
        padding-top: 94.9%;
        font-size: 0;

        > span {
            @include pos-fill();

            > * {
                height: 100%;
            }
        }

        @include breakpoint(sm) {
            padding-top: 32.5%;
        }
    }

    .content {
        width: 100%;

        .main {
            width: 100%;
            
            * {
                width: 100%;
            }
        }
    }
}