@use "@styles/helpers" as *;

.root {
    @include flex(center);
    @include rounded-corners(16px);

    width: 100%;
    margin: 16px 0;
    min-height: $mobile-delivery-info-bar-height;
    padding: 0 16px;
    background: $color-gray-light;

    @include breakpoint(lg) {
        width: calc(100% + 48px);
        margin: 0 -24px 24px;
        padding: 0 24px;
    }
}

// Icon

.iconWrapper {
    padding: 12px 0;
    min-width: 40px;
}

.iconContainer {
    @include flex(center, center, row);

    width: 36px;
    height: 36px;
    background: $color-primary;
    color: $color-white;
    border-radius: 50%;
    flex-shrink: 0;

    &.holiday {
        background: transparent;
        width: 40px;
        height: 40px;
    }
}

// Content

.content {
    flex: 1 1 0;
    min-width: 0;
    padding: 0 12px;
}

// --- Info

.info {
    @include typography(small);

    color: $color-gray-dark-subtle;
}

// --- Title

.title {
    @include typography(body-small-500);
    @include truncate-text(100%);

    color: $color-gray-dark;
    line-height: 1.4;
}

// --- Subtitle

.subtitle {
    @include flex(center, flex-start, row);
    @include typography(body-small);

    color: $color-gray-dark-subtle;
    line-height: 1.4;

    .address {
        @include truncate-text(250px);
    }
}

// Chevron

.chevronContainer {
    @include flex(center, center);

    height: 36px;

    .chevronIcon {
        color: $color-gray-semi;
    }
}

// Exports

:export {
    breakpoint: $screen-lg-min;
}
