@use "@styles/helpers" as *;

.root {
    @include flex(stretch, center, row);

    position: relative;
    top: 0;
    margin: 8px -8px;

    @include breakpoint(lg) {
        display: none;
    }

    @include breakpoint(359px, max) {
        @include flex(space-between, flex-start, column);
    }
}

.item {
    @include flex(center, center, column);

    flex: 1;
    padding: 0 8px;
    min-height: 60px;

    @include breakpoint(359px, max) {
        min-height: inherit;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }
}

.link {
    @include flex(center, flex-start, column);
    @include rounded-corners(16px);

    flex: 1;
    width: 100%;
    padding: 12px 16px;
    background: $color-gray-light;
    overflow: hidden;
    position: relative;

    .label {
        @include typography(10px, 10px, 500, 500);

        position: absolute;
        top: 12px;
        left: 0;
        padding: 0 6px;
        color: white;
        background-color: $color-new;
        border-radius: 0 4px 4px 0;
        height: 18px;
        line-height: 18px;
        letter-spacing: 0.5px;
    }

    .imageContainer {
        @include flex(center, center, row);

        max-height: 32px;
        max-width: 32px;
        margin-bottom: 4px;
    }

    .text {
        @include flex(center, center, column);
        @include typography(14px, 14px, 500, 500);

        flex: 1;
        color: $color-gray-dark;
        text-align: center;
    }

    .count {
        position: absolute;
        top: 8px;
        right: 8px;
        border-radius: 50%;
        @include typography(12px, 14px, 500, 500, sm);
        @include flex(center, center);

        color: $color-primary;
        background-color: $color-gray-light-ultra;
        width: 24px;
        height: 24px;
        border: 2px solid $color-white;

        > span {
            @include flex(center, center);

            line-height: normal;
        }

        @include breakpoint(359px, max) {
            top: calc(50% - 12px);
            right: 16px;
        }

        @include breakpoint(sm) {
            width: 36px;
            height: 36px;
        }
    }

    @include breakpoint(359px, max) {
        @include flex(center, flex-start, row);

        padding: 8px 32px 8px 16px;

        .imageContainer {
            padding: 4px;
            max-width: 40px;
            max-height: 40px;
            margin-bottom: 0;
        }

        .text {
            @include flex(flex-start, center, column);
            @include typography(14px, 16px, 500, 500);

            text-align: start;
            padding: 4px 0 4px 12px;
            padding-left: 12px;
        }
    }
}
