@use "@styles/helpers" as *;

.root {
    position: relative;
    margin: 16px -16px 8px;

    @include breakpoint(sm) {
        margin: 16px -24px 8px;
    }

    @include breakpoint(lg) {
        margin: 0;
    }
}

// Carousel

.carousel {
    height: 100%;
}

// Card

.card {
    .cardTitle {
        @include typography(body-500);
    }
}
